<template>
    <div>
        <rxNavBar title="我的预约" androidOrIOSFlag="true"></rxNavBar>
        <!-- 顶部固定区域-->
        <div class="header">
            <!-- 预约切换区域-->
            <div class="choose">
                <div class="choose-one">
                    <div :class="condition?'choose-one-textActive':'choose-one-text'" @click="showOnOrder">预约中</div>
                    <div class="choose-one-icon" v-show="condition"></div>
                </div>
                <div class="choose-line"></div>
                <div class="choose-one">
                    <div :class="!condition?'choose-one-textActive':'choose-one-text'" @click="showFinished">已完成</div>
                    <div class="choose-one-icon" v-show="!condition"></div>
                </div>
            </div>
        </div>
        <!--列表-->

        <div class="orderList">
            <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
            <van-list
                    v-model="loading"
                    :finished="finished"
                    :finished-text="noContent? '':'没有更多了'  "
                    @load="this.onLoad"
            >
            <!--预约中信息-->
            <div v-if="condition">
                <div v-if="!noContent">
                <div class="oneList" v-for="(item,index) in myOrderlist" :key="index">
                    <div class="oneListTop" @click="orderClick({row:item})">
                        <div class="oneListTop-image">
                            <img :src="item.roomPhoto==undefined? errorImg:item.roomPhoto">
                        </div>
                        <div class="oneListTop-text">
                            <div class="oneListTop-text-time">预约时间：{{item.appointmentTime}}</div>
                            <div class="oneListTop-text-area">{{item.roomFuzzyAddress}}</div>
                            <div class="oneListTop-text-detail">
                                <div class="oneListTop-text-detail-info">{{item.businessCircleName}}</div>
                                <div class="oneListTop-text-detail-line"></div>
                                <div class="oneListTop-text-detail-info">{{item.area==''||item.area==undefined?'未知':item.area}}㎡</div>
                                <div class="oneListTop-text-detail-line"></div>
                                <div class="oneListTop-text-detail-info">{{item.totalLevel}}层</div>
                            </div>
                        </div>
                    </div>
                    <div class="oneListBottom">
                        <div class="oneListBottom-cancelIcon"></div>
                        <div class="oneListBottom-cancelText" @click="clearOrder(item.seeWith_id)">取消预约</div>
                        <div class="oneListBottom-relateIcon" @click="relationTo(item)"></div>
                        <div class="oneListBottom-relateText" @click="relationTo(item)">联系管家</div>
                    </div>
                </div>
                </div>
            </div>
            <!--已完成信息-->
            <div v-else>
                <div v-if="!noContent">
                <div class="oneList" v-for="(item,index) in myOrderlist" :key="index">
                    <div class="oneListTop" @click="orderClick({row:item})">
                        <div class="oneListTop-image">
                            <img :src="item.roomPhoto==undefined? errorImg:item.roomPhoto">
                            <div class="oneListTop-image-already" v-if="item.appointmentStatus!='0'">
                                <div class="small-text">{{item.seeWithStatus}}</div>
                            </div>
                        </div>
                        <div class="oneListTop-text">
                            <div class="oneListTop-text-already" v-if="item.appointmentStatus=='0'">房源已出租</div>
                            <div class="oneListTop-text-timeGray" v-else>预约时间：{{item.appointmentTime}}</div>
                            <div :class="item.appointmentStatus=='0'?'oneListTop-text-areaGray':'oneListTop-text-area'">{{item.roomFuzzyAddress}}</div>
                            <div :class="item.appointmentStatus=='0'?'oneListTop-text-detailGray':'oneListTop-text-detail'">
                                <div class="oneListTop-text-detail-info">{{item.businessCircleName}}</div>
                                <div :class="item.appointmentStatus=='0'?'oneListTop-text-detail-lineGray':'oneListTop-text-detail-line'"></div>
                                <div class="oneListTop-text-detail-info">{{item.area}}㎡</div>
                                <div :class="item.appointmentStatus=='0'?'oneListTop-text-detail-lineGray':'oneListTop-text-detail-line'"></div>
                                <div class="oneListTop-text-detail-info">{{item.totalLevel}}层</div>
                            </div>
                        </div>
                    </div>
<!--                    <div class="oneListBottom" v-if="item.appointmentStatus=='2'">-->
<!--                        <div class="oneListBottom-relateIcon-anOther" @click="relationTo(item)"></div>-->
<!--                        <div class="oneListBottom-relateText" @click="relationTo(item)">联系管家</div>-->
<!--                    </div>-->

                    <div class="oneListBottom-anOther"v-show="item.appointmentStatus!='4'">
                        <div class="oneListBottom-anOther-top">实际看房时间：{{item.seeWithTime}}</div>
                        <div class="oneListBottom-anOther-bottom">结果反馈：{{item.dictionaryTitle}}</div>
                    </div>
                </div>
                </div>
            </div>
                <common-empty v-if="noContent"></common-empty>
                <!--拨打电话弹窗-->
                <dial-mobile :ownerMobile="temporaryOwnerMobile" :userName="temporaryOwnerName"
                             :dialogTitle="dialogTitle" :mobileShow="isMobileShow" @hideDialog="hideDialog"></dial-mobile>
            </van-list>
            </van-pull-refresh>
        </div>

        <!--空状态显示-->
<!--        <common-empty v-if="isEmptuyFlag"></common-empty>-->

    </div>
</template>

<script>
    import {NavBar} from "vant";
    import  {querySeeWithList,rentCancelSeeWith} from "../../../getData/getData";
    import {checkAndroid, checkIOS, getUserId, globaluserId, responseUtil} from "../../../libs/rongxunUtil";
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    import Vue from 'vue';
    import { List } from 'vant';
    Vue.use(List);
    import { PullRefresh } from 'vant';
    import { Toast } from 'vant';
    import dialMobile from '../../../components/rongxun/rx-dialMobile/dialMobile'
    Vue.use(PullRefresh);

    //调用android，传参roomId
    function orderToAndroid(roomId) {
        window.himi.showRoomDetail(roomId);
    }

    /********判断H5页面是否在web容器中 start*********/
    function openInWebview () {/* 返回true或false; */
        var ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == 'micromessenger') { // 微信浏览器判断
            return false;
        } else if (ua.match(/QQ/i) == 'qq') { // QQ浏览器判断
            return false;
        } else if (ua.match(/WeiBo/i) == "weibo") {
            return false;
        } else {
            if (ua.match(/Android/i) != null) {
                return ua.match(/browser/i) == null;
            } else if (ua.match(/iPhone/i) != null) {
                return ua.match(/safari/i) == null;
            } else {
                return (ua.match(/macintosh/i) == null && ua.match(/windows/i) == null);
            }
        }
    }

    export default {
        name: "myOrder",
        data(){
            return{
                isEmptuyFlag:false,
                condition:true,//用来控制两种状态的切换条件
                myOrderlist: [],//
                loading: false,
                finished: false,
                currentPage:'1',//当前页
                pageCount:'',//总页数
                numberPage:'10',//每页条数
                count: 0,
                type:"0",
                isLoading: false,
                seeWith_id:'',//带看id
                msg:'',
                noContent:false,//判断是否显示空页面
                //拨打电话弹窗
                isMobileShow:false,
                temporaryOwnerMobile:'',
                temporaryOwnerName:'',
                dialogTitle:'联系管家',
                errorImg: 'https://himihome.oss-cn-qingdao.aliyuncs.com/images/platform/templatetmp/2589de77-4277-4be7-add9-f09f2b176e57.png',
                orderList:[

                ],
                finishList:[

                ],
            }
        },
        components:{
            [NavBar.name]:NavBar,
            [List.name]:List,
            [PullRefresh.name]:PullRefresh,
            dialMobile,
            rxNavBar

        },
        created() {

        },
        methods:{
            //切换预约中
            showOnOrder(){
                var that = this
                that.condition = true
                this.type="0"
                this.default();
                that.loading=true
                that.finished = false
                this.onLoad()
            },
            //切换已完成
            showFinished(){
                var that = this
                that.condition = false
                this.type="1"
                this.default()
                that.finished = false
                that.loading=true
                this.onLoad()
            },
            //返回上一页
            leftReturn(){
              this.$router.go(-1)
            },
            onLoad:function(){//分页
                this.querySeeWithList()
            },
            onRefresh() {//刷新
                setTimeout(() => {
                    this.default();
                    this.loading = true;
                    this.finished = false
                    this.isLoading = false;
                    this.onLoad()
                    // Toast(this.msg);
                },800);
            },
            default(){//默认值
                this.pageCount=''
                this.currentPage='1'
                this.numberPage='10'
                this.myOrderlist=[]
            },

            //点击房源跳转android或者IOS
            orderClick(e){
                let roomId = e.row.roomid;
                //APP传值*******begin*********
                if (openInWebview()) {
                    if(checkAndroid()){
                        orderToAndroid(roomId);
                    }else if(checkIOS()){
                        window.webkit.messageHandlers.showRoomDetail.postMessage({body: roomId});
                    }
                }

                //APP传值*******end*********
            },

            querySeeWithList(){ //预约列表接口
                let that = this;
                let queryPersonDetailsData={
                    user_id:globaluserId(),
                    currentPage:that.currentPage,
                    numberPage:that.numberPage,
                    type:that.type,
                }
                querySeeWithList(queryPersonDetailsData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        console.log(response)
                        if (response.data.code == 0){
                            if (response.data.data.pageCount == 0){
                                that.isEmptuyFlag = true
                            }
                            that.pageCount=response.data.data.totalPage//总页数
                            var  pageCount=response.data.data.pageCount//总条数
                            var list=response.data.data.SeeWithList
                            if (pageCount == 0 || that.pageCount==0 || !list){//没有数据时
                                that.loading=false
                                that.finished = true
                                that.noContent=true//显示空状态
                            }
                            if (list&&that.pageCount != 0){//有数据时
                                var currentPage=parseInt(that.currentPage);//当前页
                                var pages=that.pageCount;//总页数
                                if(pages >= currentPage){//当前页不大于总页数
                                    that.currentPage=currentPage+1
                                    for (let i=0;i<list.length;i++){
                                        that.myOrderlist.push(list[i])
                                    }
                                    that.loading=false
                                    that.noContent=false//不显示空状态
                                    console.log("长度="+that.myOrderlist.length)
                                }else {
                                    that.loading=false
                                    that.finished = true
                                }
                            }
                        }else if (response.data.code != 0) {
                            responseUtil.alertMsg(that,response.data.msg)
                        } else {
                            responseUtil.alertMsg(that,response.data.msg)
                        }


                    })
                })
            },

            clearOrder(seeWith_id){//取消预约点击事件
              this.seeWith_id=seeWith_id;//带看id
              this.rentCancelSeeWith();//取消预约接口
              // this.myOrderlist=this.myOrderlist.splice((index,1))
            },

            rentCancelSeeWith(){ //预约取消接口
                let that = this;
                let rentCancelSeeWithData={
                    user_id:globaluserId(),
                    seeWith_id:that.seeWith_id,
                }
                rentCancelSeeWith(rentCancelSeeWithData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        if (response.data.code == 0){
                            that.onRefresh()//刷新页面
                            that.msg=response.data.msg;
                            responseUtil.alertMsg(that,response.data.msg)
                        }else if (response.data.code != 0) {
                            responseUtil.alertMsg(that,response.data.msg)
                        } else {
                            responseUtil.alertMsg(that,response.data.msg)
                        }

                        })
                    })
            },
            //拨打电话
            relationTo(item){
                    this.temporaryOwnerMobile = item.staffMobile
                    this.temporaryOwnerName = item.ownerName
                    this.isMobileShow = !this.isMobileShow
            },
            //关闭拨打电话弹窗
            hideDialog() {
                let that = this;
                that.isMobileShow = false;
            },
        },

    }
</script>

<style scoped>
    .navbar{
        background-color: white;
        border-bottom: 2px solid white;
    }
    .header{
        width: 100%;
        height: auto;
        position: fixed;
        z-index: 8;
        /*top: 52px;*/
        background-color: #f8f8f8;
    }
    .choose{
        width: 100%;
        height: auto;
        background-color: white;
        display: flex;
    }
    .choose-one{
        width: 187px;
        text-align: center;
    }
    .choose-one-textActive{
        width: 50px;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        font-weight: bold;
        margin-top: 24px;
        margin-left: 72px;
        color: rgba(255, 93, 59, 1);
        text-align: center;
        font-family: PingFangSC-Semibold;
    }
    .choose-one-text{
        width: 50px;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        margin-top: 24px;
        margin-left: 72px;
        color: rgba(153, 153, 153, 1);
        text-align: center;
        font-family: PingFangSC-Semibold;
    }
    .choose-one-icon{
        width: 20px;
        height: 4px;
        border-radius: 3px;
        background-color: rgba(255, 93, 59, 1);
        margin: 5px 0 15px 83px;
    }
    .choose-line{
        width: 2px;
        height: 17px;
        margin-top: 23px;
        background-color: rgba(239, 239, 239, 1);
    }
    .orderList{
        width: 100%;
        height: auto;
        position: relative;
        top: 65px;
    }
    .oneList{
        width: 345px;
        height: auto;
        margin: 20px 0 0 15px;
        background-color: rgba(255, 255, 255, 1);
        border-radius: 8px;
    }
    .oneListTop{
        width: 100%;
        height: 99px;
        border-bottom: 1px solid rgba(239, 239, 239, 1);
        display: flex;
        overflow: hidden;
    }
    .oneListTop-image{
        position: relative;
        width: 100px;
        height: 75px;
        margin: 10px 8px 0 10px;
        border-radius: 8px;
    }
    img{
        width: 100%;
        height: 100%;
        border-radius: 8px;
    }
    .oneListTop-image-already{
        position: absolute;
        /*z-index: 99;*/
        top: 0px;
        /*width: 29px;*/
        height: 17px;
        line-height: 17px;
        border-radius: 8px 8px 8px 0;
        background-color: rgba(153, 153, 153, 1);
        color: rgba(255, 255, 255, 1);
        font-size: 12px;
        text-align: center;
        font-family: PingFangSC-Regular;
    }
    .oneListTop-text{
        width: 227px;
        height: 100%;
    }
    .oneListTop-text-already{
        width: auto;
        height: 17px;
        margin-top: 20px;
        line-height: 17px;
        color: rgba(216, 216, 216, 1);
        font-size: 12px;
        text-align: left;
        font-family: PingFangSC-Semibold;
    }
    .oneListTop-text-time{
        width: auto;
        height: 17px;
        margin-top: 20px;
        line-height: 17px;
        color: rgba(49, 145, 255, 1);
        font-size: 12px;
        text-align: left;
        font-family: PingFangSC-Semibold;
    }
    .oneListTop-text-timeGray{
        width: auto;
        height: 17px;
        margin-top: 20px;
        line-height: 17px;
        color: rgba(153, 153, 153, 1);
        font-size: 12px;
        text-align: left;
        font-family: PingFangSC-Semibold;
    }
    .oneListTop-text-area{
        width: auto;
        height: 21px;
        margin-top: 5px;
        line-height: 21px;
        color: rgba(34, 34, 34, 1);
        font-size: 15px;
        text-align: left;
        font-family: PingFangSC-Regular;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .oneListTop-text-areaGray{
        width: auto;
        height: 21px;
        margin-top: 5px;
        line-height: 21px;
        color: rgba(216, 216, 216, 1);
        font-size: 15px;
        text-align: left;
        font-family: PingFangSC-Regular;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .oneListTop-text-detail{
        height: 17px;
        margin-top: 5px;
        color: rgba(102, 102, 102, 1);
        font-size: 12px;
        text-align: left;
        font-family: PingFangSC-Regular;
        display: flex;
        align-items: center;
    }
    .oneListTop-text-detailGray{
        height: 17px;
        margin-top: 5px;
        color: rgba(216, 216,216, 1);
        font-size: 12px;
        text-align: left;
        font-family: PingFangSC-Regular;
        display: flex;
        align-items: center;
    }
    .oneListTop-text-detail-line{
        width: 1px;
        height: 12px;
        background-color: rgba(102, 102, 102, 1);
        margin: 0 10px;
    }
    .oneListTop-text-detail-lineGray{
        width: 1px;
        height: 12px;
        background-color: rgba(216,216,216,1);
        margin: 0 10px;
    }
    .oneListBottom{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
    }
    .oneListBottom-anOther{
        width: 100%;
        height: auto;
        overflow: hidden;
    }
    .oneListBottom-cancelIcon{
        width: 14px;
        height: 14px;
        margin-left: 20px;
        background-image: url("../../../assets/images/cancle@15x.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
    }
    .oneListBottom-cancelText{
        /*width: 48px;*/
        height: 17px;
        line-height: 17px;
        margin-left: 3px;
        color: rgba(153, 153, 153, 1);
        font-size: 12px;
        text-align: center;
        font-family: PingFangSC-Regular;
    }
    .oneListBottom-relateIcon{
        width: 20px;
        height: 20px;
        margin-left: 162px;
        background-image: url("../../../assets/images/relation-phone.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
    }
    .oneListBottom-relateIcon-anOther{
        width: 20px;
        height: 20px;
        margin-left: 247px;
        background-image: url("../../../assets/images/relation-phone.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
    }
    .oneListBottom-relateText{
        /*width: 48px;*/
        height: 17px;
        line-height: 17px;
        margin-left: 10px;
        color: rgba(102, 102, 102, 1);
        font-size: 12px;
        text-align: left;
        font-family: PingFangSC-Regular;
    }
    .oneListBottom-relateText>a{
        color: rgba(102, 102, 102, 1);
    }
    .oneListBottom-anOther-top{
        width: 315px;
        height: 17px;
        margin: 15px 0 0 15px;
        line-height: 17px;
        color: rgba(153, 153, 153, 1);
        font-size: 12px;
        text-align: left;
        font-family: PingFangSC-Regular;
    }
    .oneListBottom-anOther-bottom{
        width: 315px;
        height: auto;
        margin: 0 0 16px 15px;
        line-height: 17px;
        color: rgba(153, 153, 153, 1);
        font-size: 12px;
        text-align: left;
        font-family: PingFangSC-Regular;
        overflow : hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .small-text{
        /*width: 24px;*/
        height: 17px;
        margin-left: 1px;
        font-size: 12px;
        -webkit-transform: scale(0.83);
    }
</style>
